.home{
    margin: auto;
    width: 80vw;
    margin-top: 12vh;
    text-align: center;
}

table, th, td{
    border: 1px solid black;
}
table{
    justify-self: center;
    align-self:center;
    margin: auto;
}
th, td{
    width:11rem;
}

.resi-tabel{
    width:35rem;
}

.biaya-input{
    width: 6rem;
}

.resi-tabel input{
    margin: 0 3px 0 3px;
}

.home button{
    margin: 0 3px 0 3px;
    border: 1px solid black;
    border-radius: 3px;
    cursor: pointer;
}

.input-group>.form-control, .input-group>.form-floating, .input-group>.form-select, .input-group{
    position: inherit !important;
}
 
/* ======================================== */

body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.home .modal-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color:#195A51;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 10px;
    max-width: 600px;
    min-width: 300px;
}

.modal-right img{
    max-width: 100%;
}

.close-modal{
    background: #23C050;
    border: none;
    color: white;
    padding: 0.5rem;
    border-radius: 1rem;
}

/* .tid{
    width:5rem !important;
} */