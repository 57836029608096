.login{
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login form{
    position: relative;
    width: 25rem;
    padding: 20px;
    margin: 20%;
    border: 2px solid #195A51;
    border-radius: 12px;
}

.form-group{
    padding: 10px;
}