.nav{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 3rem;
    background-color: #195A51;
}

.nav button{
    height: 100%;
    width: 12rem;
    border-radius: 5px;
    font-weight: bolder;
    font-size: large

}
